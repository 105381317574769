import email from "../assets/email.png";
import mobile from "../assets/mobile.png";
import api from "../assets/api.png";
import cpp from "../assets/cpp.png";
import css from "../assets/css.png";
import figma from "../assets/figma.png";
import flutter from "../assets/flutter.png";
import git from "../assets/git.png";
import graphql from "../assets/graphql.png";
import html from "../assets/html.png";
import javascript from "../assets/javascript.png";
import mu5 from "../assets/mu5.png";
import node from "../assets/node.png";
import python from "../assets/python.png";
import react from "../assets/react.png";
import redux from "../assets/redux.png";
import sass from "../assets/sass.png";
import typescript from "../assets/typescript.png";
import vue from "../assets/vue.png";

import circle from "../assets/circle.svg";
import logo from "../assets/logo_po.svg";

import egloo_heading from "../assets/egloo_heading_img.png";
import egloo_mobile_01 from "../assets/egloo_mobile_01.png";
import egloo_mobile_02 from "../assets/egloo_mobile_02.png";
import egloo_mobile_03 from "../assets/egloo_mobile_03.png";
import egloo_design_system from "../assets/egloo_design_system.png";

import harvey_heading_0 from "../assets/harvey_heading_img_0.gif";
import harvey_heading_1 from "../assets/harvey_heading_img_1.png";
import harvey_heading_2 from "../assets/harvey_heading_img_2.png";
import harvey_heading_3 from "../assets/harvey_heading_img_3.png";
import harvey_heading_4 from "../assets/harvey_heading_img_4.png";
// import harvey_mobile_01 from "../assets/harvey_mobile_01.png";
// import harvey_mobile_02 from "../assets/harvey_mobile_02.png";
// import harvey_mobile_03 from "../assets/harvey_mobile_03.png";
import harvey_design_system from "../assets/harvey_design_system.png";

import bg_deco_1 from "../assets/bg_deco_1.svg";
import bg_deco_2 from "../assets/bg_deco_2.svg";
import bg_deco_3 from "../assets/bg_deco_3.svg";
import bg_deco_4 from "../assets/bg_deco_4.svg";
import bg_deco_5 from "../assets/bg_deco_5.svg";
import bg_deco_6 from "../assets/bg_deco_6.svg";

import bg_desktop from "../assets/bg_desktop.svg";
import bg_tablet from "../assets/bg_tablet.svg";
import bg_mobile from "../assets/bg_mobile.svg";
import bg_pen from "../assets/bg_pen.svg";
import bg_grid from "../assets/bg_grid.svg";

import diypt_heading from "../assets/diypt_heading.jpg";
import diypt_m01 from "../assets/diypt_m01.jpg";
import diypt_m02 from "../assets/diypt_m02.jpg";
import diypt_m03 from "../assets/diypt_m03.jpg";
import diypt_d01 from "../assets/diypt_d01.jpg";
import diypt_d02 from "../assets/diypt_d02.jpg";
import diypt_d03 from "../assets/diypt_d03.jpg";
import diypt_ui from "../assets/diypt_ui_development.jpg";
import edm_builder1 from "../assets/edm_builder.mp4";
import web_edm_builder1 from "../assets/web_edm_builder.mp4";

export default {
  email,
  mobile,
  api,
  cpp,
  css,
  figma,
  flutter,
  git,
  graphql,
  html,
  javascript,
  mu5,
  node,
  python,
  react,
  redux,
  sass,
  typescript,
  vue,
  circle,
  logo,

  egloo_heading,
  egloo_mobile_01,
  egloo_mobile_02,
  egloo_mobile_03,
  egloo_design_system,
  harvey_heading_0,
  harvey_heading_1,
  harvey_heading_2,
  harvey_heading_3,
  harvey_heading_4,
  harvey_design_system,
  bg_deco_1,
  bg_deco_2,
  bg_deco_3,
  bg_deco_4,
  bg_deco_5,
  bg_deco_6,
  bg_desktop,
  bg_tablet,
  bg_mobile,
  bg_pen,
  bg_grid,
  diypt_heading,
  diypt_m01,
  diypt_m02,
  diypt_m03,
  diypt_d01,
  diypt_d02,
  diypt_d03,
  diypt_ui,
  edm_builder1,
  web_edm_builder1,
};
