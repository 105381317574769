import React, { lazy, Suspense } from "react";
// import { Footer, Header, Skills, Testimonial, Work } from "./container";
// import Home from "./pages/Home";
// import Egloo from "./pages/Egloo";
// import Harvey from "./pages/Harvey";
// import Diypt from "./pages/Diypt";
// import Page404 from "./pages/Page404";
import { Navbar } from "./components";
import "./App.scss";

import { Routes, Route, Link, NavLink, Outlet } from "react-router-dom";

const Home = lazy(() => import("./pages/Home"));
const Egloo = lazy(() => import("./pages/Egloo"));
const Harvey = lazy(() => import("./pages/Harvey"));
const Diypt = lazy(() => import("./pages/Diypt"));
const Page404 = lazy(() => import("./pages/Page404"));

const App = () => {
  return (
    <div className="app">
      {/* <Navbar />
      <Header />
      <Work />
      <Skills />
      <Testimonial />
      <Footer /> */}
      {/* <Home />
      <Egloo /> */}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/egloo" element={<Egloo />} />
          <Route path="/harveynorman" element={<Harvey />} />
          <Route path="/diypt" element={<Diypt />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default App;
